window.changePlaceHolder = function (ownId, id)
{
    let value = document.getElementById(ownId).value;
    let placeHolderId = document.getElementById(id);
    const inputTypes = {
        'input_type': '',
        'datetime-local': 'YYYY-MM-DD HH:MM',
        'date': 'YYYY-MM-DD',
        'checkbox': '0',
        'text': 'Some text',
        'color': '#000000',
        'select': "option1,option2:selected,option3",
        'email': 'Your@email.com',
        'number': '0123456789',
    };

    placeHolderId.placeholder = inputTypes[value];
}
