document.addEventListener('DOMContentLoaded', function () {
    const toggleButtons = document.querySelectorAll('[data-toggle-popup]');
    const closeButtons = document.querySelectorAll('[data-close-popup]');

    toggleButtons.forEach(function (button) {
        const popupId = button.getAttribute('data-id');

        button.addEventListener('click', function () {
            showPopup(popupId);
        });
    });

    closeButtons.forEach(function (button) {
        const popupId = button.getAttribute('data-id');

        button.addEventListener('click', function () {
            hidePopup(popupId);
        });
    });

    window.addEventListener('click', function (event) {
        const popups = document.querySelectorAll('.popup');

        popups.forEach(function (popup) {
            if (event.target == popup) {
                hidePopup(popup.id);
            }
        });
    });

    window.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            const openPopups = document.querySelectorAll('.popup[style="display: block;"]');
            if (openPopups.length > 0) {
                hidePopup(openPopups[0].id);
            }
        }
    });
});

window.showPopup = function(popupId) {
    const popup = document.getElementById(popupId);
    if (popup) {
        popup.style.display = 'block';
        createOverlay();
    }
}

window.hidePopup = function(popupId) {
    const popup = document.getElementById(popupId);
    if (popup) {
        popup.style.display = 'none';
        removeOverlay();
    }
}

function createOverlay() {
    const overlay = document.createElement('div');
    overlay.classList.add('popup-overlay');
    document.body.appendChild(overlay);
}

function removeOverlay() {
    const overlay = document.querySelector('.popup-overlay');
    if (overlay) {
        overlay.remove();
    }
}
