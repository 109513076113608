window.previewImage = function (input, previewId) {
    const preview = document.getElementById(previewId);
    const file = input.files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
        preview.style.display = 'block';
        preview.querySelector('img').src = e.target.result;
    };

    if (file) {
        reader.readAsDataURL(file);
    }
}