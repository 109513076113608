window.addEventListener('load', function () {

    if(jsSettings.blacklisted) {
        return;
    }


    let auto_logout_after_20_min = jsSettings.auto_logout_after_20_min;
    let stay_loggedin_while_browser_open = jsSettings.stay_loggedin_while_browser_open;
    let alertTitle = jsSettings.alertTitle;
    let alertText = jsSettings.alertText;
    let alertButton = jsSettings.alertButton;

    alertTitle = alertTitle.replace(/%20/g, " ");
    alertText = alertText.replace(/%20/g, " ");
    alertButton = alertButton.replace(/%20/g, " ");

    if(auto_logout_after_20_min != 0){
        let timeAlert;

        const AlertTimer = 1000 * 900;// 900 seconds / 15 minutes
        const swalTimer = 1000 * 300;// 300 seconds / 5 minutes

        resetAlertTime();

        document.body.addEventListener('click', resetAlertTime, true);
        document.body.addEventListener('keypress', resetAlertTime, true);

        function alertUser()
        {
            const swalAlert = Swal.mixin(
            {
                customClass:
                {
                    confirmButton: 'btn btn-success',
                },
                buttonsStyling: false
            })

            swalAlert.fire(
            {
                title: alertTitle,
                text: alertText,
                icon: "warning",
                timer: swalTimer,
                timerProgressBar: true,
                showCancelButton: false,
                confirmButtonText: alertButton,
            }).then((result) =>
            {
                if (result.dismiss === Swal.DismissReason.timer) {
                    logoutUser()
                }
            })
        }

        function logoutUser()
        {
            document.getElementById('logout-form').submit();
        }

        function resetAlertTime()
        {
            clearInterval(timeAlert);
            timeAlert = setInterval(alertUser, AlertTimer);
        }
    }

    if(stay_loggedin_while_browser_open != 0){
        const renewTime = 1000 * 600;// 600 seconds / 10 minutes
        let sessionInterval;

        resetSessionTime();

        function resetSessionTime()
        {
            clearInterval(sessionInterval);
            sessionInterval = setInterval(renewSession, renewTime);
        }

        function renewSession()
        {
            $.ajax({
                type : 'POST',
                url : 'keepalive',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            }).done(function( data ) {
                console.log(data);
            });
        }
    }
});
