window.getLockables = async function(route, crsf) {
    const response = await fetch(route, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': crsf,
        },
    })
    .then((response) => response.json());

    const lockedProducts = response.products;
    const allProducts = document.querySelectorAll('.is-actions');

    for (const product of allProducts) {
        const id = product.id.split('-')[1];

        if(!lockedProducts.includes(id)) {
            handleLocking(id, false)
        }
    }

    for (const product of lockedProducts) {
        handleLocking(product, true)
    }
}

function handleLocking(product, lock = false) {
    const actions = document.getElementById(`actions-${product}`);

    if(actions) {
        const lockBadge = actions.querySelector('div:first-child');
        const actionBtns = actions.querySelector('div:last-child');
    
        if(lock) {
            lockBadge.style.display = 'flex';
            actionBtns.setAttribute('style', 'display: none !important');
        } else {
            lockBadge.style.display = 'none';
            actionBtns.style.display = 'flex';
        }
    }
}