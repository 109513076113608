window.RepeaterTable = class RepeaterTable {
    constructor(tableId, languages, attributeValues = {}) {
        this.tableId = tableId;
        this.table = document.querySelector(`#${this.tableId}`);
        this.languages = languages;

        if(attributeValues) {
            this.fillRepeaterTable(attributeValues);
        }
    }

    fillRepeaterTable(attributeValues) {
        for (const attributeValue of attributeValues) {
            const row = document.createElement('tr');
            const id = this.generateUniqueId();
            row.setAttribute('id', id);

            for (let i = 0; i < this.languages.length; i++) {
                const td = document.createElement('td');
                const input = document.createElement('input');
                input.setAttribute('type', 'text');
                input.setAttribute('name', `values[${this.languages[i].code}][]`);
                input.setAttribute('id', `${this.languages[i].code}-${id}`);
                input.classList.add('form-control');
                input.value = attributeValue[i];
                td.appendChild(input);
                row.appendChild(td);
            }

            const td = document.createElement('td');
            const button = document.createElement('button');
            button.setAttribute('type', 'button');
            button.setAttribute('class', 'btn btn-sm btn-danger');
            button.setAttribute('onclick', 'window.repeaterTableCollect["'+ this.tableId +'"].removeRepeaterRow(this)');
            button.setAttribute('data-id', id);
            button.innerHTML = '<i class="fas fa-trash"></i>';
            td.appendChild(button);
            row.appendChild(td);

            this.table.appendChild(row);
        }
    }

    addRepeaterRow() {
        const row = document.createElement('tr');
        const id = this.generateUniqueId();
        row.setAttribute('id', id);

        for (let i = 0; i < this.languages.length; i++) {
            const td = document.createElement('td');
            const input = document.createElement('input');
            input.setAttribute('type', 'text');
            input.setAttribute('name', `values[${this.languages[i].code}][]`);
            input.setAttribute('id', `${this.languages[i].code}-${id}`);
            input.classList.add('form-control');
            td.appendChild(input);
            row.appendChild(td);
        }

        const td = document.createElement('td');
        const button = document.createElement('button');
        button.setAttribute('type', 'button');
        button.setAttribute('class', 'btn btn-sm btn-danger');
        button.setAttribute('onclick', 'window.repeaterTableCollect["'+ this.tableId +'"].removeRepeaterRow(this)');
        button.setAttribute('data-id', id);
        button.innerHTML = '<i class="fas fa-trash"></i>';
        td.appendChild(button);
        row.appendChild(td);

        this.table.appendChild(row);
    }

    removeRepeaterRow(button) {
        const id = button.getAttribute('data-id');
        const row = document.getElementById(id);
        if (row) {
            row.remove();
        }
    }

    generateUniqueId() {
        return (
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)
        );
    }
}
