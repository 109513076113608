require('./bootstrap');

require('./app/toggle-password');

require('./app/select-all');

require('./app/select-multiple');

require('./app/sweetalert2');

require('./app/inactivity');

require('./app/input-types');

require('./app/cropper');

require('./app/bootstrap-table/bootstrap-table');

require('./app/disable-autocomplete');

require('./app/repeater-table');

require('./app/preview-image');

require('./app/lockable-products');

require('jqtree');

require('./app/popup');

require('./app/tooltips');

import Tagify from '@yaireo/tagify';

window.Tagify = Tagify;

window.replaceNotLoadedImagesWithDefault = function(defaultImageUrl) {
    const imgElements = document.querySelectorAll('img');

    imgElements.forEach((img) => {
        img.addEventListener('error', () => {
            img.src = defaultImageUrl;
        });

        if (img && img.getAttribute('loading') != 'lazy') {
            if (!img.complete || img.naturalWidth === 0) {
                img.src = defaultImageUrl;
            }
        }
    });
}

document.addEventListener('DOMContentLoaded', function () {
    tinymce.init({
        selector: 'textarea.input-textarea, .tinymce',
        plugins: 'lists',
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist',
    });

    //Replace not loaded images with default image
    window.replaceNotLoadedImagesWithDefault('/img/placeholder.webp');

    //Add tooltip class to elements with title attribute
    const elementsWithTooltip = document.querySelectorAll('a[title], button[title]');

    elementsWithTooltip.forEach((element) => {
        element.classList.add('has-tooltip');
    });

    //slug warning on click
    const confirmBtn = document.querySelector('[data-confirm-slug]');
    const declineBtns = document.querySelectorAll('[data-decline-slug]');
    const directInputs = document.querySelectorAll('input[name*="slug"]:not([type="hidden"])');

    directInputs.forEach(function (input) {
        input.dataset.slug = input.value;

        if (input.name.includes('export')) {
            return;
        }

        input.addEventListener('click', function () {
            window.showPopup('slug-warning-popup');

            confirmBtn.dataset.inputname = input.name;

            declineBtns.forEach(function (btn) {

                btn.dataset.inputname = input.name;
            });
        });


        confirmBtn.addEventListener('click', function () {
            const slugInput = document.querySelector('input[name="' + this.dataset.inputname + '"]');
            slugInput.focus();

            window.hidePopup('slug-warning-popup');
        });

        declineBtns.forEach(function (btn) {
            btn.addEventListener('click', function () {
                const slugInput = document.querySelector('input[name="' + this.dataset.inputname + '"]');

                slugInput.value = slugInput.dataset.slug;

                window.hidePopup('slug-warning-popup');
            });
        });
    });
});

window.initCategoriesDropdown = function (id = 'categories-dropdown') {
    setTimeout(function () {
        const dropdown = document.getElementById(id);

        if (dropdown) {
            const dropdown = document.getElementById(id);

            dropdown.addEventListener("click", function(event) {
                event.stopPropagation();
            });
        
            const labels = dropdown.querySelectorAll('.categories-select__label');
            const radios = dropdown.querySelectorAll('.categories-select__input');
        
            radios.forEach(function (radio) {
                radio.addEventListener('change', function () {
                    const label = radio.parentElement.querySelector('.categories-select__label > span');
                    const dropdownButtonText = document.getElementById(id + '-show-selection');

                    dropdownButtonText.innerText = label?.innerText.replace(/<br>/g, '').trim() ?? 'Geen selectie';
                });
            });
        
            labels.forEach(function (label) {
                label.addEventListener('click', function () {
                    const children = label.parentElement.nextElementSibling;
        
                    if (children && children.classList.contains('categories-select__children')) {
                        if (children.style.display === 'none' || children.style.display === '') {
                            children.style.display = 'block';
                            label.classList.remove('categories-select__expand-button');
                            label.classList.add('categories-select__collapse-button');
                        } else {
                            children.style.display = 'none';
                            label.classList.remove('categories-select__collapse-button');
                            label.classList.add('categories-select__expand-button');
                        }
                    }
                });
            });

            return;
        }
    }, 1000);
}



